import styled from "styled-components";

export const Container = styled.div`
    height: 61px;
    .container-passos {
        display: flex;
        justify-content: space-between;
        margin-top: -1px;
        div {
            display: block;
            height: 1px;
            width: 100%;
            margin-right: 4px;
            border-bottom: 1px solid #e1e2e3;
            &.active {
                border-bottom: 1px solid
                    ${(props) => props.theme.colors.secondary};
            }
            &:last-child {
                margin-right: 0;
            }
        }
    }

    button {
        position: relative;
        text-align: center;
        height: 100%;
        width: 100%;
        font-weight: 500;
        font-family: "Oxanium";
        text-transform: uppercase;
        color: ${(props) => props.theme.colors.primary} !important;
        img {
            position: absolute;
            top: 50%;
            left: 0px;
            transform: translateY(-50%);
        }
    }

    .noTitle{
        height: 47px;
        margin:0;
    }
`;
