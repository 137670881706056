import React, { createContext, useContext } from "react";
import { useHistory } from "react-router-dom";
import { api } from "../../services";
import { UseCartoes } from "./UseCartaoes";
import { UseModal } from "../../Hook/Usemodal";
import { usePassos } from "../../Hook/UsePassos";
import { formatCurrentMoney } from "../../util/helper";

interface UsePagamentoBoletoProps {
    children: React.ReactChild | React.ReactChild[];
}

interface UsePagamentoBoletos {
    getBoleto(boleto: string): Promise<GetBoleto>;
    pagamentoBoleto(boleto: PagamentoBoleto): Promise<any>;
}

interface GetBoleto {
    allowChangeValue: boolean;
    assignor: string;
    digitable: string;
    discountValue: number;
    documentPayer: string;
    documentRecipient: string;
    dueDate: string;
    fineValueCalculated: number;
    interestValueCalculated: number;
    maxValue: number;
    minValue: number;
    payDueDate: null | string;
    payer: string;
    recipient: string;
    totalUpdated: number;
    type: number;
    value: number;
}

interface PagamentoBoleto {
    cartao_id: number;
    digitable: string;
    customerDescriptionsh?: string;
    password: string;
    cvv?: string;
}

const UsePagamentoBoleto = createContext<UsePagamentoBoletos>(
    {} as UsePagamentoBoletos
);

export function UsePagamentoBoletoProvider(props: UsePagamentoBoletoProps) {
    const { children } = props;
    const { cartao } = UseCartoes();
    const { setState, state } = UseModal();
    const { setPassos } = usePassos();
    const withScreen = window.screen.width;
    const history = useHistory();

    async function getBoleto(boleto: string) {
        const limiteBoletoCartaoFisico = 1500;
        const limiteBoletoCartaoVirtual = 500;

        try {
            const response = await api.get<GetBoleto>(
                `conta/details/${boleto}`
            );
            const saldo = await api.get<{ saldo: string }>(
                `cartao/${cartao?.id}/saldo`
            );

            const saldoUser = parseFloat(saldo.data.saldo);
            const valorBoleto = parseFloat(response.data.value + "");

            if (
                valorBoleto > limiteBoletoCartaoFisico &&
                cartao?.tipo === "cartao_fisico"
            ) {
                if (withScreen <= 480) {
                    history.push("/dashboard");
                }

                setState({
                    ...state,
                    boleto: false,
                    limiteBoleto: true,
                });

                return {} as GetBoleto;
            }

            if (
                valorBoleto > limiteBoletoCartaoVirtual &&
                cartao?.tipo === "cartao_virtual"
            ) {
                if (withScreen <= 480) {
                    history.push("/dashboard");
                }

                setState({
                    ...state,
                    boleto: false,
                    limiteBoleto: true,
                });
                return {} as GetBoleto;
            }

            if (saldoUser < valorBoleto + 3.5) {
                if (withScreen <= 480) {
                    history.push("/dashboard");
                }
                setState({
                    ...state,
                    boleto: false,
                    errorGeral: {
                        active: true,
                        title: "Saldo Insuficiente",
                        message: `
                            <p>Você não possui saldo suficiente para realizar essa operação</p>
                            <br/>
                            <p style="margin: 0px;text-align: left;">Valor do Boleto: R$${formatCurrentMoney(
                                valorBoleto
                            )}.
                            </p>

                            <p style="margin: 0px;text-align: left;">
                                Taxa de operação: R$3,50
                            </p>

                            <p style="margin: 0px;text-align: left;">
                                Saldo disponível no cartão: R$${formatCurrentMoney(
                                    saldoUser
                                )}
                            </p>

                        `,
                    },
                });
                setPassos([]);
                return {} as GetBoleto;
            }

            return { ...response.data, saldo: saldo.data.saldo };
        } catch (error: any) {
            const width = window.screen.width;
            if (error?.response.status === 500) {
                if (width <= 1024) {
                    history.goBack();
                }
            }
            return {} as GetBoleto;
        }
    }

    async function download(id: number) {
        try {
            const response = await api.get(`conta/${id}/download`, {
                responseType: "blob",
            });
            return response;
        } catch (error) {
            console.log(error);
            return error;
        }
    }

    async function pagamentoBoleto(boleto: PagamentoBoleto) {
        try {
            const pagamento = await api.request<{ id: number }>({
                method: "POST",
                url: "conta/pay",
                responseType: "json",
                data: {
                    ...boleto,
                },
            });

            const responsefile = await download(pagamento.data.id);

            return responsefile;
        } catch (error: any) {
            // console.log(error.response);
            return error.response;
        }
    }

    return (
        <UsePagamentoBoleto.Provider value={{ getBoleto, pagamentoBoleto }}>
            {children}
        </UsePagamentoBoleto.Provider>
    );
}

export function usePagamentoBoleto() {
    const context = useContext(UsePagamentoBoleto);
    return context;
}
