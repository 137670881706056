import * as React from "react";

import { UseModal } from "../../Hook/Usemodal";

import Slide from "@mui/material/Slide";
import { TransitionProps } from "@mui/material/transitions";

import { ModalStyles, Container } from "./styles";
import exclamation from "../../assets/img/exclamation.svg"
import crash from "../../assets/img/crashGreen.svg"
import crashRed from "../../assets/img/crash.svg"

const Transition = React.forwardRef(function Transition(
    props: TransitionProps & {
        children: React.ReactElement<any, any>;
    },
    ref: React.Ref<unknown>
) {
    return <Slide direction="down" ref={ref} {...props} />;
});

interface ModalProps {
    open: boolean;
    handleClose: () => void;
}

export default function ModalError(props: ModalProps) {
    const { open, handleClose } = props;
    const { state } = UseModal();

    let icon = exclamation;
    if(state?.errorGeral.title === "Ops...") icon = crash;
    if(state?.errorGeral.title === "Saldo Insuficiente") icon = crashRed;

    return (
        <ModalStyles
            open={open}
            TransitionComponent={Transition}
            keepMounted
            onClose={handleClose}
            className="modal-error"
        >
            <Container>

                <img src={icon} alt="" /> 
                
                <h2 className={`${state?.errorGeral.title === "Ops..." && "ops"}`}> 
                    {state?.errorGeral.title}
                </h2>

                {state?.errorGeral.message && (
                    <p
                        dangerouslySetInnerHTML={{
                            __html: state?.errorGeral.message,
                        }}
                    ></p>
                )}
                
                <button onClick={handleClose}>OK</button>
            </Container>
        </ModalStyles>
    );
}
