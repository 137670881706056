import styled from "styled-components";

export const Container = styled.div`
    .container {
        p {
            text-align: center;
            width: 260px;
            margin: 0 auto;
            margin-top: 19px;
            margin-bottom: 26px;
        }
    }
`;

export const SegundaViaStyles = styled.div`
    width: 100%;
    padding:0 27px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    text-align: center;
    text-align: center;

    h1 {
        margin: 23px 0 15px;

        font-style: normal;
        font-weight: 700;
        font-size: 20px;
        line-height: 120%;
        color: ${(props) => props.theme.colors.failure};
    }

    p {
        margin-bottom:9px;

        font-style: normal;
        font-weight: 400;
        font-size: 14px;
        line-height: 140%;
        text-align: center;
        color: ${(props) => props.theme.colors.primaryDark};
    }

    button{
        max-width: 280px;
        height: 2.5rem;
        margin:15px 0 0;

        font-weight: 700;
        letter-spacing: 0;
        text-transform: none;
    }

    .cancelar {
        height: 2rem;
        margin:4px 0 30px;

        font-weight: 400;
        color: #5e5f5f;
    }
`;

export const FormStyles = styled.div`
    margin: 0 auto;
    width: 90%;
    margin-bottom: 23px;
    button {
        &.open-modal-cvv {
            width: 100%;
            margin-bottom: 68px;
            
            font-style: normal;
            font-weight: 500;
            font-size: 16px;
            line-height: 140%;
            text-align: center;
            color: ${(props) => props.theme.colors.secondary};
        }
    }
`;
