import { useState } from "react";
import { UseCartoes } from "../../../Hook/user/UseCartaoes";
import { UseModal } from "../../../Hook/Usemodal";
import { Button } from "../Button";
import { Text } from "alisson-application";
import { ConfirToggleSmsStyles } from "./styles";
import imgSuccess from "../../../assets/img/sucesso.svg";
export function ConfirmaToggleSms() {
    const { handleClose } = UseModal();
    const [loading, setLoading] = useState(false);
    const { smsCartaoToggle, cartao } = UseCartoes();

    async function handleToggle() {
        setLoading(true);
        await smsCartaoToggle();
        setLoading(false);
    }
    return (
        <ConfirToggleSmsStyles>
            <img src={imgSuccess} alt="" />
            <Text as="h3">Notificações Via SMS</Text>
            {cartao?.acg_sms_service ? (
                <p>
                    <strong>
                        Está certo de que deseja desativar as notificações via SMS.
                        Você não será mais informado das transações do seu cartão.
                    </strong>
                </p>
            ) : (
                <p>
                    <strong>
                        Ao ativar a notificação via SMS, será cobrada uma taxa de R$ 2,00 por mês, que será debitada do seu cartão.
                    </strong>
                    <br/>
                    <span>Esta função avisa sobre todas as transações do seu cartão, ajudando a manter o controle e segurança da sua conta. Recomendamos fortemente para sua proteção.</span>
                </p>
            )}

            <Button
                onClick={handleToggle}
                type="submit"
                isLoading={loading}
                spiner="BallTriangle"
                spinerColor="primary"
                mb="30px"
            >
                {cartao?.acg_sms_service
                    ? "Sim, Desativar"
                    : "Confirmar Cobrança"}
            </Button>

            <button className="goBackBtn" onClick={() => handleClose("confirmaToggleSms")}>
                <Text color="primaryDark" fontSize="xs">
                    Cancelar
                </Text>
            </button>
        </ConfirToggleSmsStyles>
    );
}
