import styled from "styled-components";
import Dialog from "@mui/material/Dialog";

export const ModalStyles = styled(Dialog)(({ theme }) => ({
    "&.modal-error": {
        zIndex: 9999,
    },
    "& .MuiPaper-root": {
        width: "100%",
        minWidth: "90%",
        height: "auto",
        overflowY: "unset",
        boxShadow: "0px 0px 60px rgba(0, 0, 0, 0.05);",
        borderRadius: "10px",
    },
    ".container": {
        width: "100%",
    },

    "@media (min-width: 768px)": {
        "& .MuiPaper-root": {
            width: "327px",
            minWidth: "auto",
        },
    },
}));

export const Container = styled.div`
    width: 100%;
    text-align: center;
    padding-top:50px;
    padding-bottom: 35px;
    position: relative;

    h2 {
        font-style: normal;
        font-weight: 700;
        font-size: 20px;
        line-height: 100%;

        color: ${({ theme }) => theme.colors.failure};
        padding: 0 20px;
        margin-top: 20px;

        &.ops{
            color: ${({ theme }) => theme.colors.secondary};
        }
    }

    p {
        min-width: 274px;
        font-style: normal;
        font-weight: 400;
        font-size: 14px;
        line-height: 140%;
        text-align: center;
        color: ${({ theme }) => theme.colors.primary};
        margin: 0 auto;
        margin-top: 19px;
        padding: 0px 20px;
    }

    button {
        height: 40px;
        width: 100%;
        max-width: 273px;
        border-radius: 100px;
        margin-top:55px;

        font-family: Oxanium;
        font-size: 14px;
        font-weight: 700;
        text-align: center;
        color: #fff;
        background-color: ${({ theme }) => theme.colors.secondary};
    }

    svg {
        width: 50px;
        height: 50px;

        path {
            fill: #2b971b;
        }
    }
`;
