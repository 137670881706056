import { Text } from "alisson-application";
import { useHistory } from "react-router-dom";
import { Button } from "../Mobile/Button";
import imgIconePagamento from "../../assets/img/cardPaid.svg";
import { Container } from "./styles";
import { usePassos } from "../../Hook/UsePassos";
import { UseModal } from "../../Hook/Usemodal";
import { arrPassosBoletoPagamento } from "../PagamentoBoleto/config";

export function PagamentoRealizado() {
    const { nextPasso, setPassos, setDados } = usePassos();
    const datePagamento = new Date().toLocaleString('en-GB', {     year: "numeric",
        day: "2-digit", 
        month: "2-digit",
        hour: '2-digit', 
        minute: '2-digit' 
    }).replace(/,/g, " |").replace(/:/g, "h");

    const { handleClose } = UseModal();
    const history = useHistory();
    const width = window.screen.width;
    return (
        <Container>
            <img src={imgIconePagamento} alt="" />

            <Text as="h3" color="secondary" fontSize="mdl" bold>
                Pagamento Realizado com Sucesso!
            </Text>

            <Text as="p" color="primaryBright" fontSize="xss" mb="48px">
                {datePagamento}
            </Text>

            <Button mb="8px" onClick={() => nextPasso(6)}>
                Exibir Comprovante em PDF
            </Button>

            <Button
                onClick={() => {
                    setPassos(arrPassosBoletoPagamento);
                    setDados({});
                }}
            >
                Pagar Outra Conta
            </Button>

            <button
                className="goBackBtn"
                onClick={() => {
                    if (width <= 480) {
                        history.goBack();
                    } else {
                        handleClose("boleto");
                    }
                }}
            >
                Voltar ao início
            </button>
        </Container>
    );
}
