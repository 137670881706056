import styled from "styled-components";
import Dialog from "@mui/material/Dialog";

export const ModalStyles = styled(Dialog)(({ theme }) => ({
    "&.modal-error": {
        zIndex: 9999,
    },
    "& .MuiPaper-root": {
        width: "100%",
        minWidth: "90%",
        height: "auto",
        overflowY: "unset",
        boxShadow: "0px 0px 60px rgba(0, 0, 0, 0.05);",
        borderRadius: "10px",
    },
    ".container": {
        width: "100%",
    },

    "@media (min-width: 768px)": {
        "& .MuiPaper-root": {
            width: "auto",
            minWidth: "auto",
        },
    },
}));

export const Container = styled.div`
    width: 90%;
    text-align: center;
    padding-top: 50px;
    padding-bottom: 35px;
    position: relative;
    margin: 0 auto;

    h2 {
        width:100%;
        max-width: 295px;
        margin-top:12px;
        padding: 0px 30px;
        font-style: normal;
        font-weight: 700;
        font-size: 20px;
        line-height: 120%;
        color: ${({ theme }) => theme.colors.secondary};
    }

    p {
        width: 100%;
        font-style: normal;
        font-weight: 400;
        font-size: 14px;
        line-height: 140%;
        text-align: center;
        color: ${({ theme }) => theme.colors.primary};
        margin: 0 auto;
        margin-top: 19px;
        padding: 0px 20px;
    }
    button {
        width: 100%;
        max-width: 273px;
        height:40px;
        margin: 48px auto 0;
        border-radius: 20px;
        font-weight: 700;
        text-transform: none;
    }
`;
