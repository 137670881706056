import styled from "styled-components";

export const FirstStrikeContainer = styled.div`
    display: flex;
    flex-direction: column;
    overflow-y: visible !important;
    height: 340px;
    position: relative;
    justify-content: flex-start !important;
    padding: 50px 27px 35px;

    h1 {
        margin-top:24px;
        font-family: Oxanium;
        font-size: 20px;
        font-weight: 700;
        line-height: 28px;
        text-align: center;
        color: #EB001B;
    }

    p {
        font-family: Oxanium;
        font-size: 14px;
        font-weight: 400;
        line-height: 19.6px;
        text-align: center;
        color: #000000;
        margin: 20px 0 48px;
    }

    button {
        height: 40px;
        width: 100%;
        max-width: 273px;
        border-radius: 100px;

        font-family: Oxanium;
        font-size: 14px;
        font-weight: 700;
        text-align: center;
        color: #fff;
        background-color: #57b847;
    }
`;
