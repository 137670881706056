import styled from "styled-components";

export const Container = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    text-align: center;
    height: 340px;
    padding: 50px 11px 35px;
    


    h1 {
        flex:1;
        font-style: normal;
        font-weight: 700;
        font-size: 20px;
        line-height: 120%;
        color: ${(props) => props.theme.colors.secondarydark};
        margin-bottom: 10px;
    }

    p {
        font-style: normal;
        font-weight: 400;
        font-size: 14px;
        line-height: 140%;
        color: ${(props) => props.theme.colors.primary};
        margin-bottom: 45px;
    }


    img {
        margin-bottom:22px;        
    }

    button{
        max-width: 273px;
        height: 40px;
        font-weight: 700;
        letter-spacing: 0;
        text-transform: none;
    }

    .cancelar {
        margin: 0 auto;
        display: block;
        text-transform: uppercase;
        margin-top: 12px;
    }
`;
