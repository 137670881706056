import { Meses } from "../Desktop/Meses";
import { CardComprovante } from "../CardComprovante";
import { Container } from "./styles";
import { Header } from "./header";
import { useComprovante } from "../../Hook/user/useComprovante";
import { ExtratoBoleto } from "../../Hook/user/UseCartaoes";
import { When } from "../when";
import { Empty } from "../Mobile/Empty";
import { Loader } from "../Loader";
// import { FiltroEntradaSaida } from "../Mobile/Movimentacao/FiltroEntradaSaida";
import imgFiltro from "../../assets/img/filtro.svg";
import { FiltroDate } from "../Mobile/Movimentacao/FiltroDate";
import { useEffect, useState } from "react";
import { Button } from "../Mobile/Button";

export interface ComprovanteDePagamentoProps {
    handleClose?: () => void;
}

export function ComprovanteDePagamento({handleClose}:ComprovanteDePagamentoProps) {
    const { requestComprovante, extratos, loading } = useComprovante();
    const [antigo, setAntigo] = useState(false);
    const [recent, setRecent] = useState(false);

    const [filtro, setFiltro] = useState<ExtratoBoleto[]>([]);

    async function handleClick(date: Date) {
        setRecent(false);
        setAntigo(false);
        setFiltro([]);
        await requestComprovante(date);
    }

    useEffect(() => {
        const update = [...extratos];
        if (antigo) {
            setFiltro(update.reverse());
            return;
        }

        if (recent) {
            setFiltro(update);
            return;
        }

        setFiltro([]);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [antigo, recent]);

    

    return (
        <Container>
            <Header />
            <div className="slide-mes">
                <Meses handleClick={handleClick} />
            </div>

            <div className="container-filtros">
                {/* <FiltroEntradaSaida
                    handleEntrada={setEntrada}
                    handleSaida={setSaida}
                />
                 */}
                <When expr={extratos?.length > 0 && !loading}>
                    <>
                        <div>
                            <img src={imgFiltro} alt="filtro por data" />Filtro
                        </div>
                        <FiltroDate
                            reset={recent}
                            handleAntigo={setAntigo}
                            handleRecent={setRecent}
                        />
                    </>

                </When>
            </div>

            <div className="container-cards">
                <When expr={loading}>
                    <div className="container-loading">
                        <Loader />
                    </div>
                </When>

                <When expr={extratos?.length === 0 && !loading}>
                    <Empty modal/>
                </When>

                <When expr={extratos?.length > 0 && !loading}>
                    <>
                        {filtro.length > 0
                            ? filtro?.map((extrato) => (
                                  <CardComprovante
                                      key={extrato.id}
                                      {...extrato}
                                  />
                              ))
                            : extratos?.map((extrato) => (
                                  <CardComprovante
                                      key={extrato.id}
                                      {...extrato}
                                  />
                              ))}
                    </>
                </When>

            </div>
            {handleClose &&           
                <Button className="goBackBtn" onClick={() => handleClose()}>
                    Voltar
                </Button>
            }
        </Container>
    );
}
