import styled from "styled-components";

export const Container = styled.div`
    /* min-height: 559px; */
    /* min-height: 657px; */
    .container-menu {
        display: flex;
        justify-content: center;
        align-items: center;
        height: 99px;
        border-bottom: 1px solid #e1e2e3;
        margin: 0 5%;
    }
    /* .container-tabs {
        form {
            input {
                text-transform: unset;
            }
        }
    } */

    .container-input {
        &::after {
            display: none;
        }
    }

    .container-tabs {
        width: 90%;
        margin: 0 auto;
        padding:0;
    }
`;

export const FormStyles = styled.div`
    padding-bottom: 32px;
    p {
        text-align: center;
        margin-bottom: 26px;
    }

    form {
        height: 341px;
        display: flex;
        flex-direction: column;
        ul {
            margin-bottom: 0px;
        }
        .mt-auto {
            margin-top: 16px;
            button {
                margin-top: 0px;
            }
        }
    }
`;


