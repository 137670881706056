import styled from "styled-components";

export const Container = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    height: 100vh;
    width: 100%;
    margin: 0;
`;

export const LogoContainer = styled.div`
    display: flex;
    justify-content: center;

    width: 100%;
    padding: 0 0 25px;
    border-bottom: 1px solid #ccc;
`;

export const ModalContent = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 6px;

    padding: 20px 0 0;
    width: 100%;

    h1 {
        color: #3a3b3d;
        font-family: Oxanium;
        font-size: 14px;
        font-style: normal;
        font-weight: 500;
        letter-spacing: 1.4px;
        text-transform: uppercase;
        margin: 0 0 20px;
    }
`;

export const UploadDocCard = styled.div`
    display: flex;
    flex-direction: column;
    padding: 24px 16px;
    border-radius: 15px;
    background: #f6f6f6;

    p:first-child {
        font-weight: 700;
    }

    p.enabled {
        color: #57b847;
    }

    div.info {
        display: grid;
        grid-template-columns: 80px 1fr;
        margin-bottom: 37px;
    }

    span {
        font-size: 12px;
        font-weight: 600;
        line-height: 14.4px;
        text-align: left;
    }

    .container-frente-documento {
        margin-bottom: 16px;
    }

    label button {
        pointer-events: none;
    }

    & + button {
        margin: 10px 0 0;
    }
`;

export const FileName = styled.span`
    display: block;
    position: relative;

    font-family: Oxanium;
    font-size: 12px;
    font-weight: 400;
    line-height: 14.4px;
    text-align: left;
    color: #57b847;
    background: #efefef;

    width: 100%;
    max-width: 320px;
    padding: 15px 50px 15px 15px;
    border-radius: 5px;
    overflow: hidden;
    text-overflow: ellipsis;
    line-clamp: 1;
    white-space: nowrap;

    button {
        cursor: pointer;
        position: absolute;
        right: 15px;
        top: 12.5px;
    }
`;
