import { Button } from "../Button";
import { SegundaViaStyles } from "./styles";
import { usePassos } from "../../../Hook/UsePassos";
import { UseModal } from "../../../Hook/Usemodal";
import imgCardCancel from "../../../assets/img/cardCancel.svg";

export function PageSegundaVia() {
    const { nextPasso } = usePassos();
    const { handleClose} = UseModal();
    return (
        <SegundaViaStyles>
            
            <img src={imgCardCancel} alt="" />
            <h1>Cancelar Cartão</h1>
            <p>
                <strong>
                    Deseja realmente cancelar, <br/>
                    de forma <u>irreversível</u> este cartão?
                </strong>
            </p>
            <p>
                O crédito existente no cartão será<br/>
                transferido automaticamente para o <br/>
                Saldo na Carteira.
            </p>
            
            <Button onClick={() => nextPasso(2)} mb="32px">
                Confirmar Cancelamento
            </Button>
            <button className="cancelar"
                onClick={() => handleClose("segundaVia")}
            >
                Voltar
            </button>
        </SegundaViaStyles>
    );
}
