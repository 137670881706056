import { useEffect, useState } from "react";

import { Button } from "../Button";
import { HeaderPassos } from "../HeaderPassos";
// import imgX from "../../../assets/img/x.svg";
import imgLogoValidarUser from "../../../assets/img/logo-validar-user.svg";

import { useTranslation } from "../../../contexts/Localization";
import { usePassos } from "../../../Hook/UsePassos";
import { UseLogin } from "../../../Hook/user/UseLogin";
import { phoneNumber } from "../../../util/helper";
import { CodeInput } from "../../CodeInput";

import { Acoes } from "./acoes";
import { ValidadarStyles, LoaderContainer } from "./styles";
import { Loader } from "../../Loader";
import { Header } from "./Header";

interface ValidarProps {
    handleClose: () => void;
}

const arrPassos = [
    {
        passo: 1,
        active: true,
        completed: true,
    },
    {
        passo: 2,
        active: false,
        completed: false,
    },
];

export function Validadar(props: ValidarProps) {
    const { handleClose } = props;
    const { t } = useTranslation();
    const { formValuesRegister, user, getCode } = UseLogin();
    const [errorCode, setErrorCode] = useState(false);
    const [loading, setLoading] = useState(false);
    const [code, setCode] = useState("");
    const { setPassos, nextPasso, passos } = usePassos();
    const number = user?.phone_number
        ? phoneNumber(user.phone_number)
        : formValuesRegister?.phone_number;

    async function sendSms() {
        await getCode("whatsapp");
        nextPasso(2);
    }

    useEffect(() => {
        if (formValuesRegister?.phone_number || user?.phone_number) {
            setPassos(arrPassos);
        }
    }, [setPassos, formValuesRegister?.phone_number, user?.phone_number]);

    return (
        <ValidadarStyles className="container">
            <div className="container-logo-validar">
                <img src={imgLogoValidarUser} alt="" />
            </div>

            <HeaderPassos
                title={t("validação de cadastro")}
                handleClose={() => {
                    handleClose();
                }}
            />

            <div className="container-tabs">
                <div className={`tab ${passos[0]?.active ? "active" : ""}`}>
                    {passos[0]?.active ? (
                        <>
                            <Header />
                            <div className="container-input">
                                <input type="text" value={number} disabled />
                            </div>

                            <Button onClick={sendSms} className="enviar-sms">
                                ACESSAR
                            </Button>
                        </>
                    ) : null}
                </div>

                <div className={`tab ${passos[1]?.active ? "active" : ""}`}>
                    {passos[1]?.active ? (
                        <>
                            <Header />

                            {loading ? (
                                <LoaderContainer>
                                    <Loader width={75} height={75} />
                                </LoaderContainer>
                            ) : (
                                <CodeInput
                                    onChange={setCode}
                                    showError={errorCode}
                                />
                            )}

                            <Acoes
                                code={code}
                                setErrorCode={setErrorCode}
                                setLoading={setLoading}
                                number={number}
                            />
                        </>
                    ) : null}
                </div>
            </div>
        </ValidadarStyles>
    );
}
