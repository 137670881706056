import { Button } from "../Button";
import CardOutOfService from "../../../assets/img/cardOutOfService.svg";
import { Container } from "./styles";
import { UseModal } from "../../../Hook/Usemodal";

export function HorarioFuncionamento() {
    const { handleClose, state } = UseModal();

    return (
        <Container>
                <img className="img-close" src={CardOutOfService} alt="" />

                {state.dados.text}

                <Button onClick={() => handleClose("horarioFuncionamento")}>
                    OK
                </Button>
        </Container>
    );
}
